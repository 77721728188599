import React from 'react';
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from "react-redux";
import LkSidebar from "../lk-sidebar";
import Cookies from "js-cookie";
import {CSRFfetch, getFormData} from "../../utils/utils-django";
import Spinner from "../spinner";
import {Field, Form} from "react-final-form";
import {Input, ImageInput} from "../input/input";
import {
  userLoaded
} from "../../actions";
import {clear_server_container} from "../../utils/utils-js";

class LkPersonalPage extends React.Component {
  state = {};

  onSubmit = async (values, form, callback) => {
    const {productStoreService, userLoaded} = this.props;
    // window.alert(JSON.stringify(values, undefined, 2));

    let data = getFormData(values);
    let options = {method: 'POST', body: data};
    try {
      let response = await CSRFfetch(`/accounts/change_personal_data/`, options);
      let result = await response.json();
      if (Object.keys(result).length === 0) {
        UIkit.notification({message: 'Данные успешно сохранены', status: 'success', pos: 'top-right'});
        productStoreService.getUserData()
          .then((data) => {
            userLoaded(data);
            this.setState({loading: false});
          });
        // setTimeout(form.reset);
      }
      return result;
    } catch (Error) {
      console.log(Error);
      return {'general': 'unknownError'};
    }
  };

  renderFooter(submitting, errors) {
    let inner = null;
    if (submitting) {
      inner = <Spinner/>;
    } else {
      if (errors.general) {
        inner = <div className="unknown-error"><Msg id={errors.general}/></div>;
      }
    }
    return inner;
  }

  _render = ({handleSubmit, form, submitting, errors, submitErrors}) => {
    const csrftoken = Cookies.get('csrftoken');
    return (
      <form action="/accounts/change_personal_data/" method="POST" onSubmit={handleSubmit}>

        <Field component="input" type="hidden" name="csrfmiddlewaretoken" value={csrftoken}/>

        <fieldset className="uk-fieldset uk-grid-margin uk-first-column">

          <div className="uk-grid-small uk-child-width-1-1 uk-grid uk-grid-stack"
               uk-grid="">
            <div className="uk-first-column"><label>
              <div className="uk-form-label">Имя</div>
              <Field component={Input} className="uk-input uk-form-width-large"
                     name="first_name"
                     type="text"/></label>
            </div>
            <div className="uk-grid-margin uk-first-column"><label>
              <div className="uk-form-label">Фамилия</div>
              <Field component={Input} className="uk-input uk-form-width-large" name="last_name" type="text"/></label>
            </div>
            <div className="uk-grid-margin uk-first-column"><label>
              <div className="uk-form-label">Номер телефона</div>
              <Field component={Input} className="uk-input uk-form-width-large"
                     name="phone_number"
                     type="tel"
                     placeholder="+7 (918) 000-00-00"
              /></label>
            </div>
            <div className="uk-grid-margin uk-first-column"><label>
              <div className="uk-form-label">Аватар</div>
              <Field component={ImageInput} className="uk-input uk-form-width-large"
                     name="image"
                     type="image"
                     placeholder="+7 (918) 000-00-00"
              /></label>
            </div>
            <div className="uk-grid-margin uk-first-column">
              <button className="uk-button uk-button-primary"
                      type="submit" disabled={submitting}>Сохранить
              </button>
            </div>
          </div>
        </fieldset>

        {this.renderFooter(submitting, {...errors, ...submitErrors})}

      </form>
    )
  };


  render() {
    const {user_data} = this.props;
    const csrftoken = Cookies.get('csrftoken');
    const initialValues = user_data;
    clear_server_container();
    return (
      <main>
        <section className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-grid-medium uk-grid" data-uk-grid="">

              <LkSidebar/>

              <div className="uk-width-1-1 uk-width-expand@m">
                <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header className="uk-card-header"><h1 className="uk-h2">Персональная
                    информация</h1></header>

                  <div className="uk-card-body">


                    <Form onSubmit={this.onSubmit} render={this._render} initialValues={initialValues}>

                    </Form>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    user_data: state.user_data,
  };
};

const mapDispatchToProps = {
  userLoaded,
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(LkPersonalPage);