import React, {Component} from 'react';
import getThumbnail from "../../utils/get-thumbnail";
import {Link} from 'react-router-dom';

class CartItem extends Component {

  onHideOffCanvas = (e) => {
    UIkit.offcanvas("#cart-offcanvas").hide();
  };

  render() {
    const {
      id,
      key,
      title,
      quantity,
      image_url, price, amount, onRemoveFromCart, onCartItemPlus, onCartItemMinus, onCartItemChange, cart_type
    } = this.props;

    if (cart_type === 'full') {
      return (
        <div className="uk-card-body">
          <div
            className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-flex-middle uk-grid uk-grid-stack"
            uk-grid="">


            <div className="uk-first-column">
              <div className="uk-grid-small uk-grid" uk-grid="">
                <div className="uk-width-1-3 uk-first-column">
                  <div className="tm-ratio tm-ratio-4-3"><Link className="tm-media-box" to={`/catalog/detail_offer/${id}/`} onClick={this.onHideOffCanvas}>
                    <figure className="tm-media-box-wrap"><img src={getThumbnail(image_url, '150')}
                                                               alt=""/>
                    </figure>
                  </Link></div>
                </div>
                <div className="uk-width-expand">
                  <div className="uk-text-meta">Запчасть</div>
                  <Link className="uk-link-heading" to={`/catalog/detail_offer/${id}/`} onClick={this.onHideOffCanvas}>{title}</Link></div>
              </div>
            </div>


            <div className="uk-grid-margin uk-first-column">
              <div
                className="uk-grid-small uk-child-width-1-1 uk-child-width-expand@s uk-text-center uk-grid uk-grid-stack"
                uk-grid="">
                <div className="uk-first-column">
                  <div className="uk-text-muted uk-hidden@m">Цена</div>
                  <div>{price} руб</div>
                </div>
                <div className="tm-cart-quantity-column uk-grid-margin uk-first-column">
                  <a
                    onClick={onCartItemMinus}
                    uk-icon="icon: minus; ratio: .75"
                    className="uk-icon"
                  >
                  </a>
                  <input
                    className="uk-input tm-quantity-input"
                    id="product-2"
                    type="text"
                    maxLength="3"
                    value={quantity}
                    onChange={(e) => {onCartItemChange(e, key)}}
                  />
                  <a
                    onClick={onCartItemPlus}
                    uk-icon="icon: plus; ratio: .75"
                    className="uk-icon"
                  >
                  </a></div>
                <div className="uk-grid-margin uk-first-column">
                  <div className="uk-text-muted uk-hidden@m">Сумма</div>
                  <div>{quantity * price} руб</div>
                </div>
                <div className="uk-width-auto@s uk-grid-margin uk-first-column">
                  <a
                    className="uk-text-danger"
                    uk-tooltip="Удалить"
                    title=""
                    aria-expanded="false"
                    onClick={onRemoveFromCart}
                  >


                    <span uk-icon="close" className="uk-icon"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (cart_type === 'side') {
      return (
        <li className="uk-visible-toggle">
          <article>
            <div className="uk-grid-small uk-grid" uk-grid="">
              <div className="uk-width-1-4 uk-first-column">
                <div className="tm-ratio tm-ratio-4-3"><Link className="tm-media-box" to={`/catalog/detail_offer/${id}/`}>
                  <figure className="tm-media-box-wrap"><img src={getThumbnail(image_url, '150')}
                                                             alt={title}/>
                  </figure>
                </Link></div>
              </div>
              <div className="uk-width-expand">
                <div className="uk-text-meta uk-text-xsmall">Запчасть</div>
                <Link className="uk-link-heading uk-text-small" to={`/catalog/detail_offer/${id}/`}>{title}</Link>
                <div className="uk-margin-xsmall uk-grid-small uk-flex-middle uk-grid uk-grid-stack" uk-grid="">
                  <div className="uk-text-bolder uk-text-small uk-first-column">{quantity * price} руб</div>
                  <div className="uk-text-meta uk-text-xsmall uk-grid-margin uk-first-column">{quantity} × {price} руб
                  </div>
                </div>
              </div>
              <div>
                <a
                  className="uk-icon-link uk-text-danger uk-icon"
                  href="#"
                  uk-icon="icon: close; ratio: .75"
                  uk-tooltip="Удалить"
                  title=""
                  aria-expanded="false"
                  onClick={onRemoveFromCart}
                >
                </a></div>
            </div>
          </article>
        </li>
      );
    } else if (cart_type === 'checkout') {
      return (
        <div className="uk-grid-small uk-grid" uk-grid="">
          <div className="uk-width-expand uk-first-column">
            <div className="uk-text-small">{title}</div>
            <div className="uk-text-meta">{quantity} × {price} руб</div>
          </div>
          <div className="uk-text-right">
            <div>{quantity * price} руб</div>
          </div>
        </div>
      );
    }
  }
}

export default CartItem;