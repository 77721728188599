import React from 'react';
import LkSidebar from '../lk-sidebar';
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {clear_server_container} from "../../utils/utils-js";

class LkFavoritesPage extends React.Component {
    state = {
        data: {
            results: []
        },
    };

    onClickFavorite = (offerId) => {
        const {productStoreService} = this.props;
        console.log("Добавляем в избранное");
        productStoreService.addFavorite(offerId)
            .then((data) => {
                console.log('Добавлено в избранное');
                this.updateStateRefresh();
            });
    };

    updateStateRefresh(){
        const {productStoreService} = this.props;
        productStoreService.getFavorites()
            .then((data) => {
                this.setState({data: data});
            });
    }

    componentDidMount() {

        this.updateStateRefresh();
        window.scrollTo(0, 0);
    }

    render() {
        const results = this.state.data.results;
        clear_server_container();

        return (
            <main>
                <section className="uk-section uk-section-small">
                    <div className="uk-container">
                        <div className="uk-grid-medium uk-grid" data-uk-grid="">

                            <LkSidebar/>

                            <div className="uk-width-1-1 uk-width-expand@m">
                                <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                                    <header className="uk-card-header"><h1 className="uk-h2">Избранное</h1></header>
                                    <div className="uk-grid-collapse tm-products-list uk-grid uk-grid-stack" uk-grid="">
                                        {
                                            results.map((favorite) => {
                                                return (
                                                    <article className="tm-product-card uk-first-column">
                                                        <div className="tm-product-card-media">
                                                            <div className="tm-ratio tm-ratio-4-3"><a
                                                                className="tm-media-box"
                                                                href="#">
                                                                <div className="tm-product-card-labels"><span
                                                                    className="uk-label uk-label-warning">лидер продаж</span><span
                                                                    className="uk-label uk-label-danger">trade-in</span>
                                                                </div>
                                                                <figure className="tm-media-box-wrap"><img
                                                                    src={favorite.image_url}
                                                                    alt=""/>
                                                                </figure>
                                                            </a></div>
                                                        </div>
                                                        <div className="tm-product-card-body">
                                                            <div className="tm-product-card-info">
                                                                <div
                                                                    className="uk-text-meta uk-margin-xsmall-bottom">Продавец
                                                                </div>
                                                                <h3 className="tm-product-card-title"><Link
                                                                    className="uk-link-heading"
                                                                    to={"/catalog/detail_offer/"+favorite.offer_id+"/"}>{favorite.title}</Link></h3>
                                                                {/*
                                                                <ul className="uk-list uk-text-small tm-product-card-properties">
                                                                    <li><span
                                                                        className="uk-text-muted">Парт №: </span><span>1D010RMXN00</span>
                                                                    </li>
                                                                    <li><span
                                                                        className="uk-text-muted">Страна: </span><span>Япония</span>
                                                                    </li>
                                                                    <li><span
                                                                        className="uk-text-muted">OEM: </span><span>1D010RMXN00</span>
                                                                    </li>
                                                                    <li><span
                                                                        className="uk-text-muted">Модель двигателя: </span><span>LDA-MF5</span>
                                                                    </li>
                                                                </ul>
                                                                */}
                                                            </div>
                                                            <div className="tm-product-card-shop">
                                                                <div className="tm-product-card-prices">

                                                                    <div className="tm-product-card-price">{favorite.price} руб
                                                                    </div>
                                                                </div>
                                                                <div className="tm-product-card-add">
                                                                    <div
                                                                        className="uk-text-meta tm-product-card-actions">
                                                                        <a onClick={() => this.onClickFavorite(favorite.offer_id)}
                                                                            className="tm-product-card-action js-add-to js-add-to-favorites tm-action-button-active js-added-to"
                                                                            title="Add to favorites"><span
                                                                            uk-icon="icon: heart; ratio: .75;"
                                                                            className="uk-icon"/><span
                                                                            className="tm-product-card-action-text">Удалить</span></a>
                                                                    </div>
                                                                    <button
                                                                        className="uk-button uk-button-primary tm-product-card-add-button tm-shine js-add-to-cart">
                                                            <span className="tm-product-card-add-button-icon uk-icon"
                                                                  uk-icon="cart"/><span
                                                                        className="tm-product-card-add-button-text">Добавить в корзину</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                )
                                            })}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default compose(
    withProductStoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(LkFavoritesPage);
