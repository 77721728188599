import React from 'react';
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from "react-redux";
import LkSidebar from "../lk-sidebar";
import Cookies from "js-cookie";
import {Form, Field} from 'react-final-form';
import {getFormData, CSRFfetch} from "../../utils/utils-django";
import Spinner from "../spinner";
import {Input} from "../input/input";
import {clear_server_container} from "../../utils/utils-js";

class LkSettingsPage extends React.Component {
  state = {};

  onSubmit = async (values, form, callback) => {
    // window.alert(JSON.stringify(values, undefined, 2));

    let data = getFormData(values);
    let options = {method: 'POST', body: data};
    try {
      let response = await CSRFfetch(`/accounts/change_password/`, options);
      let result = await response.json();
      if (Object.keys(result).length === 0) {
        UIkit.notification({message: 'Новый пароль установлен', status: 'success', pos: 'top-right'});
        setTimeout(form.reset);
      }
      return result;
    } catch (Error) {
      console.log(Error);
      return {'general': 'unknownError'};
    }
  };

  renderFooter(submitting, errors) {
    let inner = null;
    if (submitting) {
      inner = <Spinner/>;
    } else {
      if (errors.general) {
        inner = <div className="unknown-error"><Msg id={errors.general} /></div>;
      }
    }
    return inner;
  }

  _render = ({handleSubmit, form, submitting, errors, submitErrors}) => {
    const csrftoken = Cookies.get('csrftoken');
    return (
      <form action="/accounts/change_password/" method="POST" onSubmit={handleSubmit}>

        <Field component="input" type="hidden" name="csrfmiddlewaretoken" value={csrftoken}/>

        <fieldset className="uk-fieldset uk-grid-margin uk-first-column">
          <legend className="uk-h4">Пароль</legend>
          <div className="uk-grid-small uk-child-width-1-1 uk-grid uk-grid-stack"
               uk-grid="">
            <div className="uk-first-column"><label>
              <div className="uk-form-label">Текущий пароль</div>
              <Field component={Input} className="uk-input uk-form-width-large"
                     name="old_password"
                     type="password"/></label>
            </div>
            <div className="uk-grid-margin uk-first-column"><label>
              <div className="uk-form-label">Новый пароль</div>
              <Field component={Input} className="uk-input uk-form-width-large" name="new_password1" type="password"/></label>
            </div>
            <div className="uk-grid-margin uk-first-column"><label>
              <div className="uk-form-label">Повторите пароль</div>
              <Field component={Input} className="uk-input uk-form-width-large"
                     name="new_password2"
                     type="password"/></label>
            </div>
            <div className="uk-grid-margin uk-first-column">
              <button className="uk-button uk-button-primary"
                      type="submit" disabled={submitting}>Обновить пароль
              </button>
            </div>
          </div>
        </fieldset>

        {this.renderFooter(submitting, {...errors, ...submitErrors})}

      </form>
    )
  };

  render() {
    clear_server_container();

    return (
      <main>
        <section className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-grid-medium uk-grid" data-uk-grid="">

              <LkSidebar/>

              <div className="uk-width-1-1 uk-width-expand@m">
                <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header className="uk-card-header"><h1 className="uk-h2">Настройки</h1></header>

                  <div className="uk-card-body">
                    <Form onSubmit={this.onSubmit} render={this._render}>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(LkSettingsPage);