import React, {Component, Fragment} from 'react';
import {debounce, throttle} from 'lodash'


import { Form, Alert } from 'uikit-react';

import SelectFilter from '../select-filter';
import Spinner from '../spinner';
import "./search_panel.css";

import {compose, findGetParam} from '../../utils';
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from 'react-redux';
import {
  carBrandsLoaded,
  carSubcategoryLoaded,
  carModelLoaded,
  carModificationLoaded,
  carBrandsRequested,
  carBrandSelected,
  categoryLoaded,
  productsLoaded,
  productsRequested,
  setProductFilter
} from "../../actions";
import {withRouter} from "react-router";

class SearchPanel extends Component {

  state = {
    car_brand_loading: false,
    car_model_loading: false,
    car_modification_loading: false,
    car_category_loading: false,
    car_sub_category_loading: false,
  };

  fromObject(params){
    let esc = encodeURIComponent;
    let ar = [];

    let query = Object.keys(params)
      .map(k => {
        if (params[k] !== '') {
          ar.push({k: k, v: params[k]})
        }
      });


     query = ar.map(k => esc(k.k)+'='+esc(k.v)).join('&');

    return query;
  }

  updateUrl(){
    const {product_filter} = this.props;
    const q = this.fromObject(product_filter);
    this.props.history.push('/?'+q);
  }

  componentDidMount() {
    const {productStoreService, carBrandsLoaded, carBrandsRequested, categoryLoaded, setProductFilter} = this.props;
    carBrandsRequested();
    this.setState({car_brand_loading: true});
    productStoreService.getCarBrand()
      .then((data) => {
        carBrandsLoaded(data);
        this.setState({car_brand_loading: false});
      });
    this.setState({car_category_loading: true});
    productStoreService.getCategory().then((data) => {
      categoryLoaded(data);
      this.setState({car_category_loading: false});
    });
    const fromUrl = this.props.location.search;
    const car_brand_param = findGetParam(fromUrl, 'car_brand', '');
    const car_model_param = findGetParam(fromUrl, 'car_model', '');
    const car_modification_param = findGetParam(fromUrl, 'car_modification', '');
    const category_param = findGetParam(fromUrl, 'category', '');
    const sub_category_param = findGetParam(fromUrl, 'sub_category', '');
    const search_text_param = findGetParam(fromUrl, 'search_text', '');


    setProductFilter({
      car_brand: car_brand_param,
      car_modification: car_modification_param,
      car_model: car_model_param,
      category: category_param,
      sub_category: sub_category_param,
      search_text: search_text_param
    });
  }

  onBrandChange = (event) => {
    const {setProductFilter, carBrandSelected} = this.props;
    let car_brand = "";
    if (event !== null) {
      car_brand = event.value;
    }

    carBrandSelected(car_brand);
    setProductFilter({car_brand: car_brand, car_modification: "", car_model: "", search_text: ""});
  };

  onModelChange = (event) => {
    const {setProductFilter} = this.props;
    let car_model = "";
    if (event !== null) {
      car_model = event.value;
    }
    setProductFilter({car_model: car_model, car_modification: "", search_text: ""});
  };

  onModificationChange = (event) => {
    const {setProductFilter} = this.props;
    let car_modification = "";
    if (event !== null) {
      car_modification = event.value;
    }
    setProductFilter({car_modification: car_modification, search_text: ""});
  };


  onCategoryChange = (event) => {
    const {setProductFilter} = this.props;
    let category = "";
    if (event !== null) {
      category = event.value;
    }
    setProductFilter({category: category, sub_category: "", search_text: ""});
  };

  onSubCategoryChange = (event) => {
    const {setProductFilter} = this.props;
    let sub_category = "";
    if (event !== null) {
      sub_category = event.value;
    }
    setProductFilter({sub_category: sub_category, search_text: ""});
  };


  productUpdate = () => {
    const {productStoreService, productsLoaded, productsRequested, product_filter} = this.props;

    const pf = product_filter;
    let countParam = 0;
    if (product_filter !== undefined && product_filter !== null) {
      Object.keys(pf).map(function (key, index) {
        if (pf[key] !== '') {
          countParam += 1;
        }
      });
    }

    if ((countParam === 0) || (countParam >= 1) || (product_filter.search_text !== "")) {
      productsRequested();
      productStoreService.getProducts(product_filter)
        .then((data) => productsLoaded(data));
    }
  };

  delayedCallProductUpdate = debounce(() => { // отложеный вызов на 800ms
    this.productUpdate();
  }, 800);

  delayedCallProductUpdateAuto = debounce(() => { // отложеный вызов на 800ms
    this.productUpdate();
  }, 50);

  componentDidUpdate(prevProps, prevState) {
    const {product_filter, productStoreService, carSubcategoryLoaded, carBrandsLoaded, carModelLoaded, carModificationLoaded} = this.props;

    if (prevProps.product_filter !== product_filter) {
      this.updateUrl();
      if (prevProps.product_filter.search_text !== product_filter.search_text) {
        this.delayedCallProductUpdate();
      } else {
        this.delayedCallProductUpdateAuto();
        //this.productUpdate();
      }

      if (prevProps.product_filter.car_brand !== product_filter.car_brand) {
        if (product_filter.car_brand !== "") {
          this.setState({car_model_loading: true});
          productStoreService.getCarModel(product_filter.car_brand)
            .then((data) => {
              carModelLoaded(data);
              this.setState({car_model_loading: false});
            });
        }
      }

      if (prevProps.product_filter.category !== product_filter.category) {
        if (product_filter.category !== "") {
          this.setState({car_sub_category_loading: true});
          productStoreService.getSubCategory(product_filter.category)
            .then((data) => {
              carSubcategoryLoaded(data);
              this.setState({car_sub_category_loading: false});
            });
        }
      }

      if (prevProps.product_filter.car_model !== product_filter.car_model) {
        if (product_filter.car_model !== "") {
          this.setState({car_modification_loading: true});
          productStoreService.getCarModification(product_filter.car_model)
            .then((data) => {
              carModificationLoaded(data);
              this.setState({car_modification_loading: false});
            });
        }
      }
    }

  }

  render() {
    const {
      car_brands, car_model, car_model_often, car_modification, car_brands_often,
      count_products, loading,
      car_category, product_filter,
      car_sub_category} = this.props;
    let finded_positions = <Spinner/>;
    if (loading) {
      finded_positions = <Spinner/>;
    } else {
      finded_positions = "Найдено " + count_products + " позиций";
    }


    return (
      <Fragment>
        <Form className="uk-grid-small uk-grid" data-uk-grid>
          <div className="uk-width-1-5@s">
            <SelectFilter
              onChangeEvent={this.onBrandChange}
              title="Марка"
              often={car_brands_often}
              options={car_brands}
              value={product_filter.car_brand}
              loading={this.state.car_brand_loading}
            />
          </div>

          <div className="uk-width-1-5@s">
            <SelectFilter
              title="Модель"
              options={car_model}
              often={car_model_often}
              onChangeEvent={this.onModelChange}
              value={product_filter.car_model}
              loading={this.state.car_model_loading}
              isDisabled={!product_filter.car_brand}
            />
          </div>

          <div className="uk-width-1-5@s">
            <SelectFilter
              title="Модификация"
              options={car_modification}
              onChangeEvent={this.onModificationChange}
              value={product_filter.car_modification}
              loading={this.state.car_modification_loading}
              isDisabled={!product_filter.car_model}
            />
          </div>

          <div className="uk-width-1-5@s">
            <SelectFilter
              title="Группа"
              options={car_category}
              onChangeEvent={this.onCategoryChange}
              value={product_filter.category}
              loading={this.state.car_category_loading}
            />
          </div>

          <div className="uk-width-1-5@s">
            <SelectFilter
              title="Подгруппа"
              options={car_sub_category}
              onChangeEvent={this.onSubCategoryChange}
              value={product_filter.sub_category}
              loading={this.state.car_sub_category_loading}
              isDisabled={!product_filter.category}
            />
          </div>
        </Form>

        <Alert className="uk-alert-primary" content={finded_positions} selectClassName=""/>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    car_brands: state.car_brands.data.results,
    car_model: state.car_model.data.results,
    car_model_often: state.car_model.data.often,
    car_brands_often: state.car_brands.data.often,
    car_modification: state.car_modification.data.results,
    car_sub_category: state.car_sub_category.data.results,
    car_category: state.car_category.data.results,
    loading: state.products.loading,
    count_products: state.products.data.count,
    product_filter: state.product_filter,

  };
};


const mapDispatchToProps = {
  carBrandsRequested,
  carBrandsLoaded,
  carBrandSelected,
  categoryLoaded,
  productsLoaded,
  productsRequested,
  setProductFilter,
  carSubcategoryLoaded,
  carModelLoaded,
  carModificationLoaded,
};

export default withRouter(compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchPanel));
