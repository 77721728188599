'use strict';
import React, {Fragment} from "react";
import "./input_classes.css";

const Input = ({input, meta, ...params}) => {
  let hasError = meta.error || meta.submitError;
  return <Fragment>
    <label>{params.label}</label>
    <input
      type={input.type}
      value={input.value}
      onChange={input.onChange}
      isInvalid={hasError}
      placeholder={params.placeholder}
      className="uk-input uk-form-width-large"
    />
    {hasError &&
      <div className="invalid_input">{meta.error || meta.submitError}</div>}
  </Fragment>;
};

const handle = (target, onChange) => {
  const reader = new FileReader();
  reader.readAsDataURL(target.files[0]);
  reader.onload = () => onChange(reader.result);
  reader.onerror = () => onChange(null);
};

const ImageInput = ({input, meta, ...params}) => {
  return <Fragment>
    <input type="file" accept="image/*" src={input.value} onChange={e => handle(e.currentTarget, input.onChange)} />
    <img src={input.value} />
  </Fragment>;
};

export {Input, ImageInput};