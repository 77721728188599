import HomePage from './home-page';
import CartPage from './cart-page';
import ProductDetailsPage from './product-details-page';
import CheckoutPage from './checkout-page';
import LkOrdersPage from './lk-orders-page';
import LkSettingsPage from './lk-settings-page';
import LkPersonalPage from './lk-personal-page';


export {
  HomePage,
  CartPage,
  ProductDetailsPage,
  CheckoutPage,
  LkOrdersPage,
  LkSettingsPage,
  LkPersonalPage
}