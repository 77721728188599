import React, {Fragment, Component} from 'react';
import './product-list-item.css';
import {Link} from 'react-router-dom';
import getThumbnail from "../../utils/get-thumbnail";
import Spinner from "../spinner";

class OfferProperty extends Component {
    state = {
        loading: false,
        loaded: false
    };

    componentDidMount() {

    }

    onClickExtend = (offerId) => {
        const {productStoreService} = this.props;
        console.log("Загружаем свойства");
        this.setState({loading: true});
        productStoreService.getOffer(offerId)
            .then((data) => {
                this.setState({data: data, loading: false, loaded: true});
            });
    };

    render() {
        const {offerId} = this.props;
        let comp = <li className="uk-button uk-button-link"
                       onClick={() => this.onClickExtend(offerId)}>Дополнительно</li>;

        if ((this.state.loading === true) && (this.state.loaded === false)) {
            comp = <li><Spinner/></li>
        }

        if ((this.state.loading === false) && (this.state.loaded === true)) {
            const results = this.state.data.results;
            let characteristic = [];
            if (results.length > 0) {
                let product_item = results[0];

                if (product_item.spare_brand__title !== '') {
                    characteristic.push({d: "Производитель", v: product_item.spare_brand__title, k: "hv"});
                }
                if (product_item.country !== '') {
                    characteristic.push({d: "Страна", v: product_item.country, k: "hc"});
                }
                if (product_item.color !== '') {
                    characteristic.push({d: "Цвет", v: product_item.color, k: "hcc"});
                }
                if (product_item.side !== '') {
                    characteristic.push({d: "Сторона", v: product_item.side, k: "hs"});
                }
                if (product_item.front_back !== '') {
                    characteristic.push({d: "Перед/зад", v: product_item.front_back, k: "hf"});
                }
                if (product_item.spare_oem !== '') {
                    characteristic.push({d: "OEM", v: product_item.spare_oem, k: "ho"});
                }
                if (product_item.gearbox_number !== '') {
                    characteristic.push({d: "Номер КПП", v: product_item.gearbox_number, k: "hg"});
                }
                if (product_item.gearbox_number_alternative !== '') {
                    characteristic.push({
                        d: "Номер КПП альтернативный",
                        v: product_item.gearbox_number_alternative,
                        k: "hgg"
                    });
                }
                if (product_item.engine_model !== '') {
                    characteristic.push({d: "Модель двигателя", v: product_item.engine_model, k: "hd"});
                }
                if (product_item.comment !== '') {
                    characteristic.push({d: "Комментарий", v: product_item.comment, k: "hcom"});
                }
                if (product_item.code !== '') {
                    characteristic.push({d: "Код товара", v: product_item.code, k: "hcode"});
                }

                comp = <Fragment>{
                    characteristic.map((chTmp) => (
                        <li key={chTmp.k}><span className="uk-text-muted">{chTmp.d}: </span><span>{chTmp.v}</span></li>
                    ))
                }</Fragment>;


            } else {
                comp = <li/>;
            }
        }

        return (<Fragment>
            {comp}
        </Fragment>)
    }
}


class ProductListItem extends React.Component {

    onClickFavorite = (offerId) => {
        const {productStoreService} = this.props;
        console.log("Добавляем в избранное");
        productStoreService.addFavorite(offerId)
            .then((data) => {
                console.log('Добавлено в избранное');
            });
    };

    render() {
        const {product, onAddedToCart, onProductDetails, productStoreService} = this.props;
        const {id, offer_id, title, image_url, part_number__title, spare_brand__title, price, price_min, price_max, num_offer} = product;
        let product_link = "/catalog/details/" + id + "/";

        if (offer_id !== undefined) {
            product_link = "/catalog/detail_offer/" + offer_id + "/";
        }

        if ((offer_id !== undefined) && (id !== undefined)) {
            product_link = "/catalog/details/" + id + "/" + offer_id + '/';
        }

        let price_show = price;
        if (price_min === price_max) {
            if (price_min === undefined) {
                price_show = price;
            } else {
                price_show = price_min;
            }
        } else {
            price_show = price_min + '-' + price_max;
        }

        let offers_amount = "";
        if (num_offer) {
            offers_amount = <span className="uk-label uk-label-success">{num_offer} предложений</span>
        }


        return (

            <article className="tm-product-card" key={id}>
                <div className="tm-product-card-media">
                    <div className="tm-ratio tm-ratio-4-3">
                        <Link
                            className="tm-media-box"
                            to={product_link}
                            onClick={onProductDetails}
                        >
                            <div className="tm-product-card-labels">
                                { /*
                                <span className="uk-label uk-label-warning">Лидер продаж</span>
                                <span className="uk-label uk-label-danger">trade-in</span>
                                    */
                                }
                            </div>
                            <figure className="tm-media-box-wrap">
                                <img src={getThumbnail(image_url, '300')} alt=""/>
                            </figure>
                        </Link>
                    </div>
                </div>
                <div className="tm-product-card-body">
                    <div className="tm-product-card-info">
                        <div className="uk-text-meta uk-margin-xsmall-bottom">Запчасть</div>
                        <h3 className="tm-product-card-title">
                            <Link
                                className="uk-link-heading"

                                to={product_link}
                                onClick={onProductDetails}
                            >
                                {title}
                            </Link>
                        </h3>
                        <ul className="uk-list uk-text-small">
                            <li><span className="uk-text-muted">Парт №: </span><span>{part_number__title}</span></li>
                            <li><span className="uk-text-muted">Бренд: </span><span>{spare_brand__title}</span></li>
                            <OfferProperty offerId={offer_id} productStoreService={productStoreService}/>
                        </ul>
                    </div>
                    <div className="tm-product-card-shop">
                        <div className="tm-product-card-prices">
                            {/*<del className="uk-text-meta">$1899.00</del>*/}
                            <div className="tm-product-card-price">{price_show} руб</div>
                        </div>
                        <div className="tm-product-card-add">
                            <div className="uk-text-meta tm-product-card-actions"><a
                                className="tm-product-card-action js-add-to js-add-to-favorites tm-action-button-active js-added-to"
                                title="Add to favorites" onClick={() => this.onClickFavorite(offer_id)}><span
                                uk-icon="icon: heart; ratio: .75;" className="uk-icon"></span><span
                                className="tm-product-card-action-text">Добавить в избранное</span></a></div>
                            <button
                                onClick={onAddedToCart}
                                className="uk-button uk-button-primary tm-product-card-add-button tm-shine js-add-to-cart">
                                <span className="tm-product-card-add-button-icon uk-icon" uk-icon="cart"></span><span
                                className="tm-product-card-add-button-text">Добавить в корзину</span>
                            </button>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
};

export default ProductListItem