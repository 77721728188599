import React, {Fragment, Component} from 'react';
import ProductDetails from '../product-details';
import {connect} from "react-redux";
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {productAddedToCart, productDetailLoaded} from "../../actions";
import {clear_server_container} from "../../utils/utils-js";

class ProductDetailsPage extends Component {

  onProductAddedToCart = (id) => {
    const { productAddedToCart } = this.props;
    UIkit.offcanvas('#cart-offcanvas').show();
    productAddedToCart(id);
  };

  render() {
    const {product_details, match} = this.props;
    clear_server_container();

    return (
      <Fragment>
        <ProductDetails
          match = {match}
          title={product_details.title}
          price={product_details.price}
          old_price={product_details.old_price}
          images={product_details.images}
          onAddedToCart={() => {this.onProductAddedToCart(match.params.product_id)}}
        />
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    product_details: state.product_details.data
  };
};

const mapDispatchToProps = {
  productDetailLoaded,
  productAddedToCart,
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductDetailsPage);
