import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from '../../utils';

import {
  setProductFilter
} from "../../actions";

class SearchField extends Component {
  onSearchText = (event) => {
    const {setProductFilter} = this.props;
    const searchText = event.target.value;
    setProductFilter({search_text: searchText});
    this.props.history.push('/');
  };

  onSearchTextClear = () => {
    const {setProductFilter} = this.props;
    const searchText = "";
    setProductFilter({search_text: searchText});
  };


  render() {
    const {product_filter} = this.props;
    return (
      <Fragment>

        <div className="uk-navbar-item uk-width-expand">
          <div className="uk-search uk-search-navbar uk-width-1-1">
            <input
              className="uk-search-input"
              type="search"
              placeholder="Что ищем ? ..."
              autoFocus
              value={product_filter.search_text}
              onChange={this.onSearchText}
            />
          </div>
        </div>

        <a
          className="uk-navbar-toggle"
          data-uk-close
          data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
          href="#"
          title="Закрыть"
          onClick={this.onSearchTextClear}
        />

      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product_filter: state.product_filter,
  };
};

const mapDispatchToProps = {
  setProductFilter,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchField));