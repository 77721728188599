import React from 'react';
import { Link } from 'react-router-dom';

const MenuLogo = () => {
  return (
    <Link id="menu-logo" className="uk-navbar-item uk-logo" to="/" ><img
              src="/static/img/logo.svg" alt="remblock.ru - Фадеева Авто"/></Link>
  )
};

export default MenuLogo;