import React from 'react';
import Checkout from '../checkout';
import {clear_server_container} from "../../utils/utils-js";


const CheckoutPage = () => {
  clear_server_container();

  return (
    <Checkout/>
  )
};

export default CheckoutPage;