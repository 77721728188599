import React, {Component, Fragment} from 'react';
import './checkout.css';
import CartItem from '../cart-item';
import {connect} from 'react-redux';
import withProductStoreService from "../hoc/with-product-store-service";
import {compose} from "../../utils";
import {
  cartLoaded, userLoaded
} from "../../actions";

class Checkout extends Component {

  initialState = {
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    success: false,

  };

  state = {without_registration: false};

  reset() {
    const {user_data} = this.props;
    const initialValues = user_data;
    this.setState({...initialValues});
  }

  onWithoutRegistration = () => {
    this.setState({without_registration: true});
  };

  componentDidMount() {
    this.reset();
    window.scrollTo(0, 0);
    const {productStoreService, userLoaded} = this.props;
    productStoreService.getUserData()
      .then((data) => {
        userLoaded(data);
        this.setState({loading: false});
        this.reset();
      });
  }

  onCheckout = () => {
    const {productStoreService, cartItems, cartLoaded} = this.props;
    let send = true;

    if (this.state.first_name === "") {
      UIkit.notification({message: 'Необходимо заполнить Имя', status: 'danger'});
      send = false;
    }

    if (this.state.last_name === "") {
      UIkit.notification({message: 'Необходимо заполнить Фамилию', status: 'danger'});
      send = false;
    }

    if (this.state.email === "") {
      UIkit.notification({message: 'Необходимо заполнить Email', status: 'danger'});
      send = false;
    }

    if (this.state.phone_number === "") {
      UIkit.notification({message: 'Необходимо заполнить Телефон', status: 'danger'});
      send = false;
    }

    if (send === true) {
      productStoreService.createOrder({cart: {...cartItems}, data: {...this.state}})
        .then((data) => {
          cartLoaded(data);
          this.reset();
          this.setState({success: true});
          // UIkit.notification({message: 'Ваш заказ успешно оформлен', status: 'success'});
          UIkit.modal(document.getElementById('order-success')).show();
        });
    }


  };


  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }


  render() {
    const {cartItems, total, discount} = this.props;
    const {user_data} = this.props;
    const success = this.state.success;

    if ((user_data.is_authenticated === false) && (this.state.without_registration === false)) {
      return (<Fragment>


          <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
            <section className="uk-text-center uk-first-column"><h1
              className="uk-margin-small-top uk-margin-remove-bottom">Оформление заказа</h1></section>


            <div className="main uk-padding">


              <div uk-grid="" className="uk-grid">
                <div className="uk-width-1-3@m uk-first-column"/>
                <div className="uk-width-1-3@m uk-width-1-1@s">
                  <h3>
                    Заказать онлайн
                    <br/>
                  </h3>


                  <a href="/accounts/login/"
                     className="uk-button uk-button-primary uk-margin-small uk-width-1-1">
                    Войти
                  </a>
                  <div>Уже зарегистрированы.</div>


                  <br/>


                  <a href="/accounts/registration/"
                     className="uk-button uk-button-primary uk-margin-small uk-width-1-1">
                    Зарегистрироваться
                  </a>
                  <div>Если вы покупаете впервые. Хотите заказать онлайн, но ещё не зарегистрированы.</div>

                  <br/>
                  <h4>
                    Заказ через менеджера
                    <br/>
                  </h4>


                  <button
                    className="uk-button uk-button-default uk-margin-small uk-width-1-1"
                    onClick={this.onWithoutRegistration}
                  >
                    Без регистрации
                  </button>
                  <div>Менеджер свяжется с Вами.</div>

                </div>
                <div className="uk-width-1-3@m"/>
              </div>

            </div>


          </div>



          <div id="order-success" data-uk-modal>
            <div className="uk-modal-dialog">
              <button className="uk-modal-close-default" type="button" data-uk-close/>

              <div className="uk-modal-header">
                <h2 className="uk-modal-title">Заказ оформлен</h2>
              </div>

              <div className="uk-modal-body" data-uk-overflow-auto>

                <p>Заказ отправлен в отдел продаж.</p>
                <p>Как только мы обработаем ваш заказ. Мы свяжемся с Вами по тем контактам которые вы указали при
                  оформлении заказа</p>

              </div>

              <div className="uk-modal-footer uk-text-right">
                <button className="uk-button uk-button-primary" type="button" onClick={() => {
                  UIkit.modal(document.getElementById('order-success')).hide();
                }}>ОК
                </button>
              </div>
            </div>
          </div>


        </Fragment>
      )
    } else {

      return (<Fragment>


          <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
            <section className="uk-text-center uk-first-column"><h1
              className="uk-margin-small-top uk-margin-remove-bottom">Оформление заказа</h1></section>
            <section className="uk-grid-margin uk-first-column">
              <div className="uk-grid-medium uk-grid" uk-grid="">
                <form id="form_checkout"
                      className="uk-form-stacked uk-width-1-1 tm-checkout uk-width-expand@m uk-first-column">
                  <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
                    <section className="uk-first-column">
                      <h2 className="tm-checkout-title">1. Контактная информация</h2>
                      <div className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container">
                        <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-grid" uk-grid="">
                          <div className="uk-first-column"><label>
                            <div className="uk-form-label uk-form-label-required">Имя</div>
                            <input
                              className="uk-input"
                              type="text"
                              required=""
                              name="first_name"
                              onChange={(value) => this.onChange(value)}
                              value={this.state.first_name}
                            /></label></div>
                          <div><label>
                            <div className="uk-form-label uk-form-label-required">Фамилия</div>
                            <input
                              className="uk-input"
                              type="text"
                              required=""
                              name="last_name"
                              onChange={(value) => this.onChange(value)}
                              value={this.state.last_name}
                            /></label></div>
                          <div className="uk-grid-margin uk-first-column"><label>
                            <div className="uk-form-label uk-form-label-required">Телефон</div>
                            <input
                              className="uk-input"
                              type="tel"
                              required=""
                              name="phone_number"
                              onChange={(value) => this.onChange(value)}
                              value={this.state.phone_number}
                            /></label></div>
                          <div className="uk-grid-margin"><label>
                            <div className="uk-form-label uk-form-label-required" name="email">Email</div>
                            <input
                              className="uk-input"
                              type="email"
                              required=""
                              name="email"
                              onChange={(value) => this.onChange(value)}
                              value={this.state.email}
                            /></label></div>
                        </div>
                      </div>
                    </section>
                    <section className="uk-grid-margin uk-first-column"><h2 className="tm-checkout-title">2.
                      Доставка</h2>
                      <div
                        className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container">&nbsp;</div>
                    </section>
                    <section className="uk-grid-margin uk-first-column"><h2 className="tm-checkout-title">3. Оплата</h2>
                      <div
                        className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container">&nbsp;</div>
                    </section>
                  </div>
                </form>
                <div className="uk-width-1-1 uk-width-1-4@m tm-aside-column">
                  <div className="uk-card uk-card-default uk-card-small tm-ignore-container uk-sticky"
                       uk-sticky="offset: 90; bottom: true; media: @m;">
                    <section className="uk-card-body"><h4>Позиции в заказе</h4>


                      {
                        cartItems.map((item) => {
                            return (
                              <CartItem
                                key={item.id}
                                image_url={item.image_url}
                                title={item.title}
                                quantity={item.quantity}
                                price={item.price}
                                cart_type={'checkout'}
                                onRemoveFromCart={() => {
                                }}
                                onCartItemPlus={() => {
                                }}
                                onCartItemMinus={() => {
                                }}

                              />
                            );
                          }
                        )
                      }


                    </section>
                    <section className="uk-card-body">
                      <div className="uk-grid-small uk-grid" uk-grid="">
                        <div className="uk-width-expand uk-first-column">
                          <div className="uk-text-muted">Доставка</div>
                        </div>
                        <div className="uk-text-right">
                          <div>Забрать из магазина</div>
                          <div className="uk-text-meta">Бесплатно завтра</div>
                        </div>
                      </div>
                      <div className="uk-grid-small uk-grid" uk-grid="">
                        <div className="uk-width-expand uk-first-column">
                          <div className="uk-text-muted">Оплата</div>
                        </div>
                        <div className="uk-text-right">
                          <div>Онлайн по карте</div>
                        </div>
                      </div>
                    </section>
                    <section className="uk-card-body">
                      <div className="uk-grid-small uk-grid" uk-grid="">
                        <div className="uk-width-expand uk-first-column">
                          <div className="uk-text-muted">Подитог</div>
                        </div>
                        <div className="uk-text-right">
                          <div>{total} руб</div>
                        </div>
                      </div>
                      <div className="uk-grid-small uk-grid" uk-grid="">
                        <div className="uk-width-expand uk-first-column">
                          <div className="uk-text-muted">Скидка</div>
                        </div>
                        <div className="uk-text-right">
                          <div className="uk-text-danger">−{discount} руб</div>
                        </div>
                      </div>
                    </section>
                    <section className="uk-card-body">
                      <div className="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                        <div className="uk-width-expand uk-first-column">
                          <div className="uk-text-muted">Всего</div>
                        </div>
                        <div className="uk-text-right">
                          <div className="uk-text-lead uk-text-bolder">{total} руб</div>
                        </div>
                      </div>
                      <button form="form_checkout" type="submit"
                              className="uk-button uk-button-primary uk-margin-small uk-width-1-1"
                              onClick={() => {
                                this.onCheckout()
                              }}
                      >
                        Заказать
                      </button>
                    </section>
                  </div>
                  {/*<div className="uk-sticky-placeholder" hidden=""></div>*/}
                </div>
              </div>
            </section>
          </div>


          <div id="order-success" data-uk-modal>
            <div className="uk-modal-dialog">
              <button className="uk-modal-close-default" type="button" data-uk-close/>

              <div className="uk-modal-header">
                <h2 className="uk-modal-title">Заказ оформлен</h2>
              </div>

              <div className="uk-modal-body" data-uk-overflow-auto>

                <p>Заказ отправлен в отдел продаж.</p>
                <p>Как только мы обработаем ваш заказ. Мы свяжемся с Вами по тем контактам которые вы указали при
                  оформлении заказа</p>

              </div>

              <div className="uk-modal-footer uk-text-right">
                <button className="uk-button uk-button-primary" type="button" onClick={() => {
                  UIkit.modal(document.getElementById('order-success')).hide();
                }}>ОК
                </button>
              </div>
            </div>
          </div>

        </Fragment>
      )
    }
  }

}


const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    total: state.cart.total,
    discount: state.cart.discount,
    user_data: state.user_data,
  };
};

const mapDispatchToProps = {
  cartLoaded,
  userLoaded,
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);