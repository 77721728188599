import React from 'react';
import LkSidebar from '../lk-sidebar';
import {cartLoaded} from "../../actions";
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from "react-redux";
import {clear_server_container} from "../../utils/utils-js";

class LkOrdersPage extends React.Component {
    state = {
        data: {
            results: []
        },
    };

    componentDidMount() {
        const {productStoreService} = this.props;
        productStoreService.getOrders()
            .then((data) => {
                this.setState({data: data});
            });
        window.scrollTo(0, 0);
    }

    render() {

        const results = this.state.data.results;
        clear_server_container();

        return (
            <main>
                <section className="uk-section uk-section-small">
                    <div className="uk-container">
                        <div className="uk-grid-medium uk-grid" data-uk-grid="">

                            <LkSidebar/>

                            <div className="uk-width-1-1 uk-width-expand@m">
                                <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                                    <header className="uk-card-header"><h1 className="uk-h2">Заказы</h1></header>

                                    {
                                        results.map((order) => {
                                            return (
                                                <section className="uk-card-body" key={order.id}><h3><a className="uk-link-heading"
                                                                                         href="#">#{order.id}
                                                    <span
                                                        className="uk-text-muted uk-text-small"> от {order.created}</span></a>
                                                </h3>
                                                    <table
                                                        className="uk-table uk-table-small uk-table-justify uk-table-responsive uk-table-divider uk-margin-small-top uk-margin-remove-bottom">
                                                        <tbody>
                                                        <tr>
                                                            <th className="uk-width-medium">Товары</th>
                                                            <td>{order.quantity}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="uk-width-medium">Доставка</th>
                                                            <td>{order.delivery_type.value}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="uk-width-medium">Оплата</th>
                                                            <td>{order.payment_type.value}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="uk-width-medium">Всего</th>
                                                            <td>{order.total_amount} руб</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="uk-width-medium">Статус</th>
                                                            <td><span className="uk-label">{order.status.value}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </section>
                                            )
                                        })
                                    }


                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }

}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default compose(
    withProductStoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(LkOrdersPage);