import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

class CartMenuButtton extends Component {
  render(){
    const {quantity} = this.props;
    return (
      <Fragment>
        <span uk-icon="icon: cart" className="uk-icon"/>
        <span className = "uk-badge" > {quantity} </span>
      </Fragment>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    quantity: state.cart.quantity,
  };
};

export default connect(mapStateToProps)(CartMenuButtton);