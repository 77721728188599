import React, {Component, Fragment} from 'react';
import './select-filter.css';


import Select, {components} from 'react-select';
import chroma from 'chroma-js';

import Spinner from '../spinner';

const Placeholder = props => {
  return <components.Placeholder {...props} />;
};

const ValueContainer = ({children, ...props}) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

class SelectFilter extends Component {
  state = {};

  render() {
    const {selectClassName, title, often, options, onChangeEvent, value, loading, isDisabled} = this.props;
    let component;

    let oftenOptions = [];
    let allOptions = [];

    const labelTitle = title + '...';

    allOptions.push({value: '', label: labelTitle, color: '#464646', fontWeight: 'normal'});

    options.map((option) => {
      return (
        allOptions.push({value: option.id, label: option.title, color: '#464646', fontWeight: 'normal'})
      )
    });

    if (often) {
      often.map((option) => {
        return (
          oftenOptions.push({value: option.id, label: option.title, color: '#391f19', fontWeight: 'bold'})
        )
      });
    }

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    const groupedOptions = [
      {
        label: 'Популярные',
        options: oftenOptions,
      },
      {
        label: 'Все',
        options: allOptions,
      },
    ];

    const value_select =
      allOptions.filter(obj => {
        return obj.value.toString() === value.toString()
      });


    const colourStyles = {
      control: styles => ({...styles, backgroundColor: 'white'}),
      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? data.color
              : isFocused
                ? color.alpha(0.1).css()
                : null,
          fontWeight: data.fontWeight,
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },
    };


    component = (

      <Select
        name={title}
        isClearable
        components={{ValueContainer}}
        placeholder={() => {
          return allOptions[0].label + '...'
        }}
        isLoading={loading}
        defaultValue={allOptions[0]}
        options={groupedOptions}
        className={selectClassName}
        formatGroupLabel={formatGroupLabel}
        value={value_select[0]}
        onChange={(e) => onChangeEvent(e)}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        styles={colourStyles}
      />


    );

    return (
      <Fragment>
        {component}
      </Fragment>
    )
  }
}

export default SelectFilter

// export default compose(
//   withProductStoreService,
//   connect(mapStateToProps)
// )(SelectFilter);
