import React, {Component} from 'react';
import CartItem from '../cart-item';
import {connect} from 'react-redux';
import './cart.css';
import {Link, NavLink} from 'react-router-dom';
import {cartLoaded, cartRequested, cartItemPlus, cartItemMinus, removeFromCart} from "../../actions";
import { compose } from '../../utils';
import withProductStoreService from "../hoc/with-product-store-service";

class Cart extends Component {

  componentDidMount(){
    const { productStoreService, cartLoaded, cartRequested } = this.props;
    const { cartItems } = this.props;
    if (cartItems.length === 0) {
      cartRequested();
      productStoreService.getCart()
        .then((data) => cartLoaded(data));
    }
    window.scrollTo(0, 0);
  }

  onRemoveFromCart = (id) => {
    const { removeFromCart } = this.props;
    removeFromCart(id);
  };

  onCartItemPlus = (id) => {
    const { cartItemPlus } = this.props;
    cartItemPlus(id);
  };

  onCartItemMinus = (id) => {
    const { cartItemMinus } = this.props;
    cartItemMinus(id);
  };

  onCartItemChange = (e, key) => {
    console.log(e.target.value);
  };

  onScrollUp = (e) => {
  };

  onHideOffCanvas = (e) => {
    UIkit.offcanvas("#cart-offcanvas").hide();
  };


  render() {
    const {cartItems, total, cart_type, discount} = this.props;

    if (cart_type === 'full') {
      return (
        <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
          <div className="uk-text-center uk-first-column">
            <h1 className="uk-margin-small-top uk-margin-remove-bottom">Корзина</h1></div>
          <div className="uk-grid-margin uk-first-column">
            <div className="uk-grid-medium uk-grid uk-grid-stack" uk-grid="">
              <div className="uk-width-1-1 uk-width-expand@m uk-first-column">
                <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header
                    className="uk-card-header uk-text-uppercase uk-text-muted uk-text-center uk-text-small uk-visible@m">
                    <div className="uk-grid-small uk-child-width-1-2 uk-grid" uk-grid="">
                      <div className="uk-first-column">Запчасть</div>
                      <div>
                        <div className="uk-grid-small uk-child-width-expand uk-grid" uk-grid="">
                          <div className="uk-first-column">Цена</div>
                          <div className="tm-quantity-column">Количество</div>
                          <div>Сумма</div>
                          <div className="uk-width-auto">
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  {
                    cartItems.map((item) => {
                        return (
                          <CartItem
                            id={item.id}
                            key={item.id}
                            image_url={item.image_url}
                            title={item.title}
                            quantity={item.quantity}
                            price={item.price}
                            cart_type={cart_type}
                            onRemoveFromCart={() => {this.onRemoveFromCart(item.id)}}
                            onCartItemPlus={() => {this.onCartItemPlus(item.id)}}
                            onCartItemMinus={() => {this.onCartItemMinus(item.id)}}
                            onCartItemChange={() => {this.onCartItemChange}}
                          />
                        );
                      }
                    )
                  }

                  <div className="uk-card-footer"><label><span className="uk-form-label uk-margin-small-right">Промокод</span>
                    <div className="uk-inline"><a className="uk-form-icon uk-form-icon-flip uk-icon" href="#"
                                                  uk-icon="arrow-right">

                    </a><input className="uk-input uk-form-width-small" type="text" /></div>
                  </label></div>
                </div>
              </div>
              <div className="uk-width-1-1 tm-aside-column uk-width-1-4@m uk-grid-margin uk-first-column">
                <div className="uk-card uk-card-default uk-card-small tm-ignore-container uk-sticky"
                     uk-sticky="offset: 90; bottom: true; media: @m;">
                  <div className="uk-card-body">
                    <div className="uk-grid-small uk-grid" uk-grid="">
                      <div className="uk-width-expand uk-text-muted uk-first-column">Подитог</div>
                      <div>{total} руб</div>
                    </div>
                    <div className="uk-grid-small uk-grid" uk-grid="">
                      <div className="uk-width-expand uk-text-muted uk-first-column">Скидка</div>
                      <div className="uk-text-danger">−{discount} руб</div>
                    </div>
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                      <div className="uk-width-expand uk-text-muted uk-first-column">Итого</div>
                      <div className="uk-text-lead uk-text-bolder">{total} руб</div>
                    </div>
                    <Link className="uk-button uk-button-primary uk-margin-small uk-width-1-1"
                       to="/catalog/checkout/">Заказать</Link></div>
                </div>
                {/*<div className="uk-sticky-placeholder" hidden=""></div>*/}
              </div>
            </div>
          </div>
        </div>
      )

    } else if (cart_type === 'side'){

      return (<aside className="uk-offcanvas-bar uk-padding-remove">
        <div className="uk-card uk-card-default uk-card-small uk-height-1-1 uk-flex uk-flex-column tm-shadow-remove">
          <header className="uk-card-header uk-flex uk-flex-middle">
            <div className="uk-grid-small uk-flex-1 uk-grid" uk-grid="">
              <div className="uk-width-expand uk-first-column">
                <div className="uk-h3">Корзина</div>
              </div>
              <button className="uk-offcanvas-close uk-close uk-icon" type="button" uk-close="" title="Закрыть">
              </button>
            </div>
          </header>
          <div className="uk-card-body uk-overflow-auto">
            <ul className="uk-list uk-list-divider">
              {
                cartItems.map((item) => {
                    return (
                      <CartItem
                        id={item.id}
                        key={item.id}
                        image_url={item.image_url}
                        title={item.title}
                        quantity={item.quantity}
                        price={item.price}
                        cart_type={cart_type}
                        onRemoveFromCart={() => {this.onRemoveFromCart(item.id)}}

                      />
                    );
                  }
                )
              }

            </ul>
          </div>
          <footer className="uk-card-footer">
            <div className="uk-grid-small uk-grid" uk-grid="">
              <div className="uk-width-expand uk-text-muted uk-h4 uk-first-column">Подитог</div>
              <div className="uk-h4 uk-text-bolder">{total} руб</div>
            </div>
            <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-margin-small uk-grid uk-grid-stack"
                 uk-grid="">

              <div className="uk-first-column">
                <Link
                  className="uk-button uk-button-secondary uk-margin-small uk-width-1-1"
                  to='/catalog/cart/'
                  onClick={this.onHideOffCanvas}
                >В корзину</Link>

              </div>



              <div className="uk-grid-margin uk-first-column">
                <Link
                  className="uk-button uk-button-primary uk-margin-small uk-width-1-1"
                  to="/catalog/checkout/"
                  onClick={this.onHideOffCanvas}
                >Оформить</Link>
              </div>
            </div>
          </footer>
        </div>
      </aside>);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    total: state.cart.total,
    discount: state.cart.discount,
  };
};

const mapDispatchToProps = {
  cartLoaded,
  cartRequested,
  removeFromCart,
  cartItemPlus,
  cartItemMinus
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Cart);