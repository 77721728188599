import React from 'react';
import { Link } from "react-router-dom";
import MenuAvatar from "../menu-avatar/menu-avatar";

const LkSidebar = () => {
  return (
    <div className="uk-width-1-1 uk-width-1-4@m tm-aside-column uk-first-column">
      <div className="uk-card uk-card-default uk-card-small tm-ignore-container uk-sticky"
           uk-sticky="offset: 90; bottom: true; media: @m;">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-child-width-1-1 uk-grid uk-grid-stack" data-uk-grid="">
            <MenuAvatar/>
            <div className="uk-grid-margin uk-first-column">
              <div className="uk-grid-small uk-flex-center uk-grid" data-uk-grid="">
                <div className="uk-first-column"><Link className="uk-button uk-button-default uk-button-small"
                                                    to="/lk/settings/"><span
                  className="uk-margin-xsmall-right uk-icon"
                  uk-icon="icon: cog; ratio: .75;"/><span>Настройки</span></Link>
                </div>
                <div>
                  <a className="uk-button uk-button-default uk-button-small" href="/accounts/logout/" title="Log out"><span
                    uk-icon="icon: sign-out; ratio: .75;" className="uk-icon"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <nav>
            <ul className="uk-nav uk-nav-default tm-nav">
              <li className="uk-active"><Link to="/lk/orders/">Заказы
                </Link></li>
              <li><Link to="/lk/favorites/">Избранное
                </Link></li>
              <li><Link to="/lk/personal/">Персональная информация</Link></li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <div className="uk-sticky-placeholder" hidden=""/> */}
    </div>
  )
};

export default LkSidebar;