import React, {Fragment} from 'react';
import ProductList from "../product-list/product-list";
import SearchPanel from "../search-panel";
import {clear_server_container} from "../../utils/utils-js";


const HomePage = () => {
  clear_server_container();
  return <Fragment><SearchPanel/><ProductList /></Fragment>
};

export default HomePage;