import React, {Component, Fragment} from 'react';
import ProductListItem from '../product-list-item';
import {connect} from 'react-redux';
import './product-list.css';
import Spinner from '../spinner';
import withProductStoreService from "../hoc/with-product-store-service";
import {
  productsLoaded,
  productsRequested,
  productAddedToCart,
  productDetailLoaded,
  productsAppendLoaded
} from "../../actions";
import {compose} from '../../utils';

import {Button, Alert} from 'uikit-react';


import BottomScrollListener from 'react-bottom-scroll-listener';
import {debounce} from "lodash";

class ProductList extends Component {

  state = {
    loadingAppend: false
  };

  delayedCallProductUpdateAuto = debounce(() => { // отложеный вызов на 100ms
    const {productStoreService, productsLoaded, productsRequested, product_filter} = this.props;
    productsRequested();
    productStoreService.getProducts(product_filter)
      .then((data) => {
        productsLoaded(data);
        this.setState({loadingAppend: false});
      });

  }, 100);

  componentDidMount() {
    window.scrollTo(0, 0);
    this.delayedCallProductUpdateAuto();
  }

  onProductAddedToCart = (id, title, image_url, price) => {
    const {productAddedToCart} = this.props;
    UIkit.offcanvas('#cart-offcanvas').show();

    let product = {
      id: parseInt(id),
      title: title,
      image_url: image_url,
      price: price
    };
    productAddedToCart(product);
  };

  onProductDetails = (product) => {
    const {productDetailLoaded} = this.props;
    productDetailLoaded({id: product.offer_id, price: product.price, title: product.title, images: [product.image_url]});
  };

  onProductAppend = (toForward) => {
    const {productStoreService, productsAppendLoaded, productsRequested, next_url, previous_url} = this.props;
    if (this.state.loadingAppend === false) {

      if (next_url && toForward) {
        this.setState({loadingAppend: true});
        //productsRequested();
        productStoreService.getProductsAppend(next_url)
          .then((data) => {
            productsAppendLoaded(data);
            this.setState({loadingAppend: false});
          });
      }

      if (previous_url && !toForward) {
        this.setState({loadingAppend: true});
        productsRequested();
        productStoreService.getProductsAppend(previous_url)
          .then((data) => {
            productsAppendLoaded(data);
            this.setState({loadingAppend: false});
          });
      }
    }
  };

  render() {
    const {products, next_url, productStoreService} = this.props;
    let prev_next = (
      <div>
        <Button onClick={() => this.onProductAppend(false)}>Назад</Button>
        <Button onClick={() => this.onProductAppend(true)}>Вперед</Button>
      </div>
    );
    prev_next = "";


    let loadingAppendSpinner = "";

    if (this.state.loadingAppend === true){
      const spinner = <Spinner/>;
      loadingAppendSpinner = <Alert className="uk-alert-primary" content={spinner} selectClassName=""/>;
    } else if (next_url){
      const loadingButton = <Button className="uk-button-primary" onClick={() => this.onProductAppend(true)}>Загрузить ещё</Button>;
      loadingAppendSpinner = <Alert className="uk-alert-primary" content={loadingButton} selectClassName=""/>;
    }

    return (
      <Fragment>

        {prev_next}
        <BottomScrollListener
          onBottom={() => this.onProductAppend(true)}
        offset="2000">

          <div

            className="uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid uk-grid uk-grid-stack uk-grid">
            {
              products.map((product) => {

                return (
                  <ProductListItem
                    product={product}
                    key={product.id}
                    onAddedToCart={() => {
                      this.onProductAddedToCart(product.offer_id, product.title, product.image_url, product.price)
                    }}
                    onProductDetails={() => this.onProductDetails(product)}
                    productStoreService={productStoreService}
                  />
                )

              })
            }

          </div>

        </BottomScrollListener>
        {prev_next}
        {loadingAppendSpinner}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.data.results,
    previous_url: state.products.data.previous,
    next_url: state.products.data.next,
    product_filter: state.product_filter,
  };
};

const mapDispatchToProps = {
  productsLoaded,
  productsRequested,
  productAddedToCart,
  productDetailLoaded,
  productsAppendLoaded
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductList);